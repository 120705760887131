.App {
  text-align: center;
}

#link {
 
  margin-top: 10px;
  margin-bottom: -120px;
  max-width: 100%;
}
a {
  color: red;
  text-decoration: none;
  text-shadow: rgb(230, 158, 158) 0px 0px 10px;
}

.App-logo {
  background: pink;
  height: 75vmin;
  max-width: 100%;
  pointer-events: none;
  }

.info:hover {  transform: scale(1.05, 1.05);;}

.App-body {
  background-color: #81563d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  
  position: center;
  color: black;
  border: black solid 8px;
  padding: 10px;
  margin-top: 50px;

}

/* GLOBAL STYLES */
body {
  background-color: #000000;
  padding: 0px;
  margin: 0px;

}

#gradient {
  width: 100%;
  height: 800px;
  padding: 0px;
  margin: 0px;
}




.v {
  background: rgba(252, 252, 252, 0.24);
  overflow: scroll;
  -webkit-animation: v 8s infinite;
  animation: v 8s infinite;
  font-size: 50px;
}

code {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.info {
  background: orange;
  transition: ease-in 5s;
  transform: translate(0);
  text-shadow: black 7px 0px 7px;
  text-align: center;
  color: rgb(79, 62, 62);
  font-size: 100px;
  margin-bottom: 25px;
  margin-top: 150px; 
  height: auto;
  padding: 25px;
  
  

}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 24%;
  padding: 0 4px;

}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}